#experience {
    margin-top: 0;
}

.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background-color: var(--color-bg-varient);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color : var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    color : var(--color-primary);
}


/*media queries medium devices*/

@media screen and (max-width: 1024px){
    #experience {
        padding: 20rem 0;
    }
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/*media queries small devices*/

@media screen and (max-width: 768px){
    #experience {
        margin-top: 35rem;
        padding-top: 0;
    }
    .experience__container {
        gap : 1rem;
    }
    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}

@media screen and (max-width: 1880px) and (max-height: 667px) {
    #experience {
        margin-top: 10rem;
    }
}


@media screen and (max-width: 1400px) and (max-height: 667px) {
    #experience {
        margin-top: 5rem;
    }
}

@media screen and (max-width: 770px) and (max-height: 667px) {
    #experience {
        margin-top: 40rem;
    }
}

@media screen and (max-width: 1658px) and (max-height: 667px) {
    #experience {
        margin-top: 40rem;
    }
}

@media screen and (max-width: 1549px) and (max-height: 667px) {
    #experience {
        margin-top: 35rem;
    }
}

@media screen and (max-width: 1024px) and (max-height: 667px) {
    #experience {
        margin-top: 10rem;
    }
}

@media screen and (max-width: 772px) and (max-height: 667px) {
    #experience {
        margin-top: 5rem;
    }
}

@media screen and (max-width: 768px) and (max-height: 667px) {
    #experience {
        margin-top: 37rem;
    }
}







