header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.25rem;
    justify-content: center;
}

/* Social Buttons */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Image */
.me {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.imageme {
    position: absolute;
    bottom: 8rem;
    right: 0rem;
    border-radius: 12rem 12rem 0 0;
}

/* Scroll Down */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* Media Queries */
/* Large devices (laptops/desktops, 1024px and up) */
@media screen and (min-width: 1024px) {
    /* Adjust styles for large screens here */
}

/* Medium devices (tablets, 768px to 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    header {
        padding-top: 5rem;
    }

    .me {
        width: 18rem;
        height: 25rem;
        left: calc(50% - 9rem);
    }

    .imageme {
        bottom: 5rem;
    }

    /* Adjust other styles for tablet screens here */
}

/* Small devices (phones, up to 767px) */
@media screen and (max-width: 767px) {
    header {
        padding-top: 3rem;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

    .me {
        width: 15rem;
        height: 20rem;
        left: calc(50% - 7.5rem);
    }

    .imageme {
        bottom: 3rem;
    }

    /* Adjust other styles for mobile screens here */
}
